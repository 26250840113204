export default class CustomChartMethods {
  hexToRGB (hex, alpha = 0) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgba(${r}, ${g}, ${b})`
  }

  getColors (colorsList, seriesList, labelsList) {
    let rebColors = []
    let filterSeries = []
    const ColorOpacity = Array.from({ length: 10 }, (_, i) => i + 1)
    const filterColors = colorsList.filter((c) => c)
    if (seriesList) {
      filterSeries = seriesList.filter((s) => s.label)
    } else {
      filterSeries = labelsList.filter((l) => l)
    }
    rebColors = filterColors.map((c) => this.hexToRGB(c))
    if (filterColors.length <= filterSeries.length) {
      const rgbColorsLength = rebColors.length
      ColorOpacity.forEach((c, index) => {
        if (rebColors.length < filterSeries.length) {
          for (let i = 0; i < rgbColorsLength; i++) {
            let rgb = rebColors[i].match(/\d+/g)
            rgb = rgb.map(Number)
            rebColors.push(`rgb(${rgb[0] * (1 - 0.15 * c)}, ${rgb[1] * (1 - 0.15 * c)},  ${rgb[2] * (1 - 0.15 * c)})`)
            // rebColors.push(`rgb(${rgb[0] + (255 - rgb[0]) * c * 0.1}, ${rgb[1] + (255 - rgb[1]) * c * 0.1},  ${rgb[2] + (255 - rgb[2]) * i * 0.1})`);
          }
        }
      })
    }
    return rebColors
  }

  getChartData (chartForm, chartSampleData) {
    let colors = []
    if (chartForm.chartType === 'PieChart' || chartForm.chartType === 'DoughnutChart') {
      colors = this.getColors(chartForm.colorSchemeLst, null, chartSampleData.labels)
    } else {
      colors = this.getColors(chartForm.colorSchemeLst, chartSampleData.series, null)
    }
    return {
      labels: chartSampleData.labels.filter((x) => x),
      datasets: chartSampleData.series
        .filter((s) => s.label)
        .map((s, index) => {
          const options = {}
          if (chartForm.chartType === 'PieChart' || chartForm.chartType === 'DoughnutChart') {
            options.hoverOffset = 4
            options.backgroundColor = colors
          } else if (chartForm.chartType === 'LineChart') {
            options.borderColor = colors[index]
            options.backgroundColor = colors[index]
            options.fill = false
            options.tension = 0.1
          } else {
            options.backgroundColor = colors[index]
            options.borderWidth = 0
            options.barThickness = 10
          }
          return { ...s, ...options }
        })
    }
  }
}
